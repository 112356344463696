// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";

// 2. Include any default variable overrides here
$font-size-base: 0.875rem;
$primary: #b02a37;

// 3. Include remainder of required Bootstrap stylesheets
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
// @import "bootstrap/scss/accordion";
// @import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";
// @import "bootstrap/scss/placeholders";

@import "bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "bootstrap/scss/utilities/api";

// 8. Add additional custom code here
@import 'npm:@fortawesome/fontawesome-pro/css/fontawesome.css';
@import 'npm:@fortawesome/fontawesome-pro/css/solid.css';
@import 'npm:tom-select/dist/css/tom-select.bootstrap5.css';
@import 'npm:leaflet/dist/leaflet.css';
@import 'npm:leaflet.markercluster/dist/MarkerCluster.css';
@import 'npm:leaflet.markercluster/dist/MarkerCluster.Default.css';

body {
  padding-top: 56px;
}

.btn i.fas:not(.fa-fw) {
  @extend .me-1;
}

@media (max-width: 991.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 56px; /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #343a40;
    transition: transform .3s ease-in-out, visibility .3s ease-in-out;
  }
  .offcanvas-collapse.open {
    visibility: visible;
    transform: translateX(-100%);
  }
}

.nav-scroller .nav {
  color: rgba(255, 255, 255, .75);
}

.nav-scroller .nav-link {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: .875rem;
  color: #6c757d;
}

.nav-scroller .nav-link:hover {
  color: #007bff;
}

.nav-scroller .active {
  font-weight: 500;
  color: #343a40;
}

.bg-purple {
  background-color: #6f42c1;
}


.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.ks-big-logo {
  margin-bottom: 2rem;
  svg {
    height: 10rem;
    width: auto;
  }
}

.navbar-brand svg {
  height: 30px;
  width: auto;
}

.ks-form-login {
  padding: 15px;
  z-index: 2;
}

.ks-form-slim {
  max-width: 330px;
}

.ks-form-wide {
  max-width: 660px;
}

.ks-box {
  @extend .my-3;
  @extend .p-3;
  @extend .bg-body;
  @extend .rounded;
  @extend .shadow-sm;
}

.ks-links-link {
  padding: .1875rem .5rem;
  margin-top: .125rem;
  margin-left: 1.125rem;
  color: var(--bs-body-color);
  text-decoration: if($link-decoration == none, null, none);

  &:hover,
  &:focus,
  &.active {
    color: var(--bs-emphasis-color);
    text-decoration: if($link-hover-decoration == underline, none, null);
    background-color: lighten($primary,50%);
  }

  &.active {
    font-weight: 600;
  }
}

.form-label {
  font-weight: 500;
}

.pristine-error {
  color: $red;
}

.errorlist {
  padding-left: 1rem;
}

.ks-statform--row:nth-child(2n) > td:not(.ks-statform--white) {
  background: $gray-200;
}

.ks-question-input.input-multiplechoice {
  width: 40%;
}

.ks-question-input input[aria-invalid=true] {
  @extend .is-invalid;
}

@include media-breakpoint-up(lg) {
  .ks-library-form {
    position: relative;
    display: grid;
    grid-template-areas: "main sidebar";
    grid-template-columns: 4fr 1fr;
    gap: 3rem;
  }

  .ks-sidebar {
    grid-area: sidebar;
    position: sticky;
    top: 5rem;
    right: 0;
    height: calc(100vh - 10rem);
  }
}

.ks-form {
  grid-area: main;
  min-width: 1px;
}

.table--row-header td {
  background: $body-secondary-bg;
}